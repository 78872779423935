<template>
    <div class="content">
        <van-sticky>
            <van-search v-model="search" placeholder="请输入搜索关键词" @search="onSearch"/>
        </van-sticky>
        <van-index-bar :index-list="FirstPin" :sticky="false">
            <div v-for="(item,index) in areasList" :key="index">
                <van-index-anchor v-if="item!=0" :index="index" style="float: left;margin-left: 5px;"/>
                <van-cell v-for="(row,indexs) in item" :key="indexs" @click="getName(row.id,row.parkingName)">
                    {{ row.parkingName}}
                </van-cell>
            </div>
        </van-index-bar>
    </div>
</template>
<script>
    import {Sticky, NavBar, Search, IndexBar, IndexAnchor, Cell} from 'vant'
    import bus from '../directives/bus'
    import {parkingList} from "../api/interface"

    export default {
        name: "parkingLot",
        components: {
            [Search.name]: Search,
            [NavBar.name]: NavBar,
            [Sticky.name]: Sticky,
            [IndexBar.name]: IndexBar,
            [IndexAnchor.name]: IndexAnchor,
            [Cell.name]: Cell
        },
        data() {
            return {
                FirstPin: [
                    "A",
                    "B",
                    "C",
                    "D",
                    "E",
                    "F",
                    "G",
                    "H",
                    "J",
                    "K",
                    "L",
                    "M",
                    "N",
                    "P",
                    "Q",
                    "R",
                    "S",
                    "T",
                    "W",
                    "X",
                    "Y",
                    "Z"
                ],
                areaList: {},
                search: '',
                areasList: [],
            }
        },
        mounted() {
            this.getParkingList();
        },
        methods: {
            getParkingList() {
                let param = {
                    parkingName: this.search,
                    cityId: this.$route.query.cityId
                }
                parkingList(param).then((res) => {
                    //成功进这个
                    this.areasList = res.data.data
                    let cityName = {};
                    this.FirstPin.forEach(item => {
                        cityName[item] = [];
                        this.areasList.forEach(el => {
                            //对比开头字母是否对应
                            let first = el.start.substring(0, 1).toUpperCase();
                            if (first == item) {
                                cityName[item].push(el);
                            }
                        });
                    });
                    this.$nextTick(() => {
                        this.areasList = cityName;
                    })
                }).catch(err => { //报错进这个
                    console.log(err)
                });
            },
            onSearch(value) {
                this.search = value;
                this.getParkingList();
            },
            getName(id, name) {
                bus.$emit('parking', id, name, this.$route.query.carNumber,this.$route.query.value);
                this.$router.go(-1)
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#efefef')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            // to.meta.keepAlive = true;
            console.log(to.meta)
            next()
        }
    }
</script>

<style lang="less" scoped>
    .content {
        background: #eee;
    }

    ::v-deep .van-index-anchor {
        padding: 0;

        b {
            padding-left: 10px;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .van-cell {
        margin: 0 auto;
        width: 100%;
        box-shadow: none;

        .van-cell__title {
            text-align: left;
        }
    }
</style>
